<template>
  <div class="layout">
    <div class="menu">
      <router-link to="/dashboard" class="logo">
        <img src="@img/logo.png" alt="logo">
      </router-link>

      <div class="el-menu-demo">
        <router-link to="/news">新闻管理</router-link>
        <router-link to="/team">团队管理</router-link>
        <router-link to="/partner">合作伙伴</router-link>
        <router-link to="/jobs">职位管理</router-link>
        <router-link to="/resume">简历管理</router-link>
        <router-link to="/comments">留言管理</router-link>
        <router-link to="/setting">系统管理</router-link>
      </div>

      <el-dropdown trigger="click" class="dropdown" @command="handleCommand">
        <span class="el-dropdown-link">
          {{ userInfo.username }} <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="/password">修改密码</el-dropdown-item>
          <el-dropdown-item command="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="main">
      <el-breadcrumb separator="/" v-if="$route.meta.bread !== undefined" style="margin-bottom: 15px;">
        <el-breadcrumb-item :to="{ name: 'dashboard' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ name: item.name }" v-for="item in $route.meta.bread" :key="item.name">{{ item.title }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ $route.meta.title }}</el-breadcrumb-item>
      </el-breadcrumb>
      <router-view />
    </div>
  </div>
</template>
<style>
  .main {
    padding: 15px;
  }
  .el-menu-demo {
    display: inline-block;
    margin-left: 50px;
    position: relative;
  }
  .el-menu-demo a {
    margin: 0 15px;
  }
  .el-menu-demo a.router-link-active {
    font-weight: bold;
    color: #fff;
    border-bottom: 1px solid #ccc;
  }
</style>
<script>
import { mapGetters, mapActions } from 'vuex'
import { logout } from '@/request/modules/user'
export default {
  data () {
    return {
      activeIndex: this.$route.fullPath
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo'
    })
  },
  methods: {
    ...mapActions({
      setUserInfo: 'user/setUserInfo',
      clearUserInfo: 'user/clearUserInfo'
    }),
    handleCommand (path) {
      if (path === 'logout') {
        this.logout()
      } else {
        this.$router.push(path)
      }
    },
    logout () {
      logout().then(() => {
        this.clearUserInfo()
        this.$router.push('/')
      }).catch(() => {
      })
    }
  },
  mounted () {
    this.setUserInfo()
  }
}
</script>
